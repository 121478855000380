import React from "react";
import "./styles.scss";
import { AccessInfoFormProps, AccessInformationDBTypes, AccessType, InputFieldEnum } from "./types";
import { renderInputField } from "./renderInputField";

const AccessInfoForm: React.FC<AccessInfoFormProps> = ({
  minimized,
  mlsOuids,
  state,
  setState,
  handleShowErrorMessage,
  handleSelectAccessType,
}) => (
  <div className={minimized ? "componentContainer__minimized" : "componentContainer"}>
    {Object.values(state).map((accessType: AccessType, index) => (
      <div
        key={accessType.type}
        className="accessTypeContainer"
        tabIndex={0}
        onClick={() => handleSelectAccessType(accessType)}
      >
        {accessType.isSelected && (
          <input
            type="hidden"
            value={AccessInformationDBTypes[accessType.type]}
            name={`order[access_informations][${index}][type]`}
            id={`order_access_informations_${index}_type`}
          />
        )}
        <div className="checkboxContainer">
          <input
            type="checkbox"
            className="inputCheckbox"
            checked={accessType.isSelected}
            readOnly
          />
        </div>
        <div className="accessTypeContentsContainer">
          <div className="accessTypeTitle">{accessType.title}</div>
          {accessType?.subtitle && (
            <div className="accessTypeSubtitle">{accessType.subtitle}</div>
          )}
          {accessType.isSelected && (
            <div
              className="inputFieldsContainer"
              onBlur={() => handleShowErrorMessage(accessType)}
            >
              {accessType.inputFields.map((inputField) =>
                <div
                  key={inputField}
                  className={
                    inputField !== InputFieldEnum.brand && inputField !== InputFieldEnum.code && inputField !== InputFieldEnum.extension
                    ? "inputFieldFlexContainer__stretch"
                    : "inputFieldFlexContainer"
                  }
                >
                  {renderInputField(accessType, inputField, setState, minimized, mlsOuids, index)}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    ))}
  </div>
);

export default AccessInfoForm;
