import React from "react";
import {
  AccessTypeEnum,
  AppLockboxBrandEnum,
  InputFieldEnum,
  InputFieldErrorEnum,
  InputFieldPlaceholderEnum,
} from "./types";
import { formatPhoneNumber, unformatPhoneNumber } from "../../utils/input";

export const renderInputField = (
  accessType: any,
  inputField: InputFieldEnum,
  setState: (key: AccessTypeEnum, value: any) => void,
  minimized: boolean,
  mlsOuids:  { [id: string]: string },
  index: number,
) => {
  switch (inputField) {
    case InputFieldEnum.brand:
      return (
        <div className="inputFieldContainer">
          <input
            type="hidden"
            value={accessType.brand}
            name={`order[access_informations][${index}][lockbox_brand]`}
            id={`order_access_informations_${index}_lockbox_brand`}
          />
          <label className="inputFieldTitle"> {inputField} </label>
          <select
            name="appLockboxBrands"
            className={accessType.showBrandError ? "inputDropdown__brand__error" : "inputDropdown__brand"}
            onChange={(e) => setState(accessType.type, { ...accessType, brand: e.target.value })}
            onFocus={() => setState(accessType.type, {...accessType, showBrandError: false})}
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
            size={minimized ? 2 : 1}
          >
            <option key={'placeholder'}> {InputFieldPlaceholderEnum.brand} </option>
            {Object.values(AppLockboxBrandEnum).map((appLockboxBrand) => (
              <option key={appLockboxBrand}> {appLockboxBrand} </option>
            ))}
          </select>
          {accessType.showBrandError && (
            <div className="inputFieldErrorText">
              {InputFieldErrorEnum.brand}
            </div> 
          )}
        </div>
      );
    case InputFieldEnum.code:
      return (
        <div className="inputFieldContainer">
        <input
          type="hidden"
          value={accessType.code}
          name={`order[access_informations][${index}][code]`}
          id={`order_access_informations_${index}_code`}
        />
          <label className="inputFieldTitle"> {inputField} </label>
          <input
            value={accessType.code}
            placeholder={InputFieldPlaceholderEnum.code}
            className={accessType.showCodeError ? "inputText__code__error" : "inputText__code"}
            maxLength={10}
            onChange={(e) => setState(accessType.type, { ...accessType, code: e.target.value })}
            onFocus={() => setState(accessType.type, { ...accessType, showCodeError: false })}
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
          />
          {accessType.showCodeError && (
            <div className="inputFieldErrorText">
              {InputFieldErrorEnum.code}
            </div> 
          )}
        </div>
      );
    case InputFieldEnum.directions:
      return (
        <div className="inputFieldContainer__stretch">
        <input
          type="hidden"
          value={accessType.directions}
          name={`order[access_informations][${index}][directions]`}
          id={`order_access_informations_${index}_directions`}
        />
          <label className="inputFieldTitle"> {inputField} </label>
          {minimized ? (
            <textarea
              rows={2}
              value={accessType.directions}
              placeholder={InputFieldPlaceholderEnum.directions}
              className={accessType.showDirectionsError ? "inputText__error" : "inputText"}
              maxLength={250}
              onChange={(e) => setState(accessType.type, { ...accessType, directions: e.target.value })}
              onFocus={() => setState(accessType.type, { ...accessType, showDirectionsError: false })}
              onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
            /> 
          ) : ( 
            <input
              value={accessType.directions}
              placeholder={InputFieldPlaceholderEnum.directions}
              className={accessType.showDirectionsError ? "inputText__error" : "inputText"}
              maxLength={250}
              onChange={(e) => setState(accessType.type, { ...accessType, directions: e.target.value })}
              onFocus={() => setState(accessType.type, { ...accessType, showDirectionsError: false })}
              onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
            /> )
          }
          {accessType.showDirectionsError && (
            <div className="inputFieldErrorText">
              {accessType.type === AccessTypeEnum.communityAccess && InputFieldErrorEnum.directionsCommunity}
              {accessType.type === AccessTypeEnum.doorCode && InputFieldErrorEnum.directionsDoor}
              {accessType.type === AccessTypeEnum.hiddenKey && InputFieldErrorEnum.directionsHiddenKey}
              {accessType.type === AccessTypeEnum.appEnabledLockbox && InputFieldErrorEnum.directionsLockbox}
              {accessType.type === AccessTypeEnum.combinationLockbox && InputFieldErrorEnum.directionsLockbox}
            </div> 
          )}
        </div>
      );
    case InputFieldEnum.fullName:
      return (
        <div className="inputFieldContainer__stretch">
          <input
            type="hidden"
            value={accessType.fullName}
            name={`order[access_informations][${index}][contact_name]`}
            id={`order_access_informations_${index}_contact_name`}
          />
          <label className="inputFieldTitle"> {inputField} </label>
          <input
            className={accessType.showFullNameError ? "inputText__error" : "inputText"}
            value={accessType.fullName}
            placeholder={InputFieldPlaceholderEnum.fullName}
            maxLength={50}
            onChange={(e) => setState(accessType.type, { ...accessType, fullName: e.target.value })}
            onFocus={() => setState(accessType.type, { ...accessType, showFullNameError: false })}
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
          />
          {accessType.showFullNameError && (
            <div className="inputFieldErrorText">
              {InputFieldErrorEnum.fullName}
            </div> 
          )}
        </div>
      );
    case InputFieldEnum.mlsId:
      return (
        <div className="inputFieldContainer__stretch">
          <input
            type="hidden"
            value={accessType.mlsId}
            name={`order[access_informations][${index}][mls_id]`}
            id={`order_access_informations_${index}_mls_id`}
          />
          <label className="inputFieldTitle"> {inputField} </label>
          <input
            className="inputText"
            value={accessType.mlsId}
            placeholder={InputFieldPlaceholderEnum.mlsId}
            maxLength={20}
            onChange={(e) => setState(accessType.type, { ...accessType, mlsId: e.target.value })}
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
          />
        </div>
      );
    case InputFieldEnum.mlsOuid:
      return (
        <div className="inputFieldContainer__stretch">
          <input
            type="hidden"
            value={accessType.mlsOuid}
            name={`order[access_informations][${index}][mls_name]`}
            id={`order_access_informations_${index}_mls_name`}
          />
          <label className="inputFieldTitle"> {inputField} </label>
          <select
            className={accessType.showMlsOuidError ? "inputDropdown__error" : "inputDropdown"}
            onChange={(e) => setState(accessType.type, { ...accessType, mlsOuid: e.target.value })}
            onFocus={() => setState(accessType.type, { ...accessType, showMlsOuidError: false })}
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
            size={minimized ? 2 : 1}
          >
            {Object.values(mlsOuids).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((mlsName) => (
              <option key={mlsName}> {mlsName} </option>
            ))}
          </select>
          {accessType.showMlsOuidError && (
            <div className="inputFieldErrorText">
              {InputFieldErrorEnum.mlsOuid}
            </div> 
          )}
        </div>
      );
    case InputFieldEnum.phoneNumber:
      return (
        <div className="inputFieldContainer__stretch">
          <input
            type="hidden"
            value={`${formatPhoneNumber(accessType.phoneNumber)}${accessType.extension ? ` ext ${accessType.extension}` : '' }`}
            name={`order[access_informations][${index}][contact_phone_number]`}
            id={`order_access_informations_${index}_contact_phone_number`}
          />
          <label className="inputFieldTitle"> {inputField} </label>
          <input
            placeholder={InputFieldPlaceholderEnum.phoneNumber}
            className={accessType.showPhoneNumberError ? "inputText__error" : "inputText"}
            value={formatPhoneNumber(accessType.phoneNumber)}
            onChange={(e) => setState(accessType.type, { ...accessType, phoneNumber: unformatPhoneNumber(e.target.value) })}
            onFocus={() => setState(accessType.type, { ...accessType, showPhoneNumberError: false })}
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
          />
          {accessType.showPhoneNumberError && (
            <div className="inputFieldErrorText">
              {InputFieldErrorEnum.phoneNumber}
            </div> 
          )}
        </div>
      );
    case InputFieldEnum.extension:
      return (
        <div className="inputFieldContainer">
        <label className="inputFieldTitle"> {inputField} </label>
        <input
          value={accessType.extension}
          placeholder={InputFieldPlaceholderEnum.extension}
          className="inputText__code"
          maxLength={5}
          onChange={(e) => setState(accessType.type, { ...accessType, extension: e.target.value })}
          onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
        />
      </div>
      );
    default:
      return (
        <></>
      );
  }
};
