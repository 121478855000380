import React, {ChangeEvent, MouseEventHandler, useState} from "react";
import { usePlacesWidget } from 'react-google-autocomplete';
import './styles.scss';
import PropManagementSVG from '../../../assets/images/prop management.svg';
import EmptyLandSVG from '../../../assets/images/land-vacant.svg';
import SingleFamilySVG from '../../../assets/images/single family.svg';
import MinusOutlined from '../../../assets/images/minus_outlined.svg';
import PlusOutlined from '../../../assets/images/plus_outlined.svg';

interface PropertyDetail {
    id: number,
    address: string,
    street2: string
}

interface OrderUnit {
    id: string | null,
    name: string
}

interface Props {
    showMultiUnit: boolean,
    showLot: boolean,
    propertyDetail: PropertyDetail,
    orderUnits: OrderUnit[],
    apiKey?: string,
}

type UnitType = 'single_unit' | 'multi_unit' | 'lot';

const AddressUnits: React.FC<Props> = ({showMultiUnit, showLot, propertyDetail, orderUnits: initialOrderUnits, apiKey}) => {
    const [unitType, setUnitType] = useState<UnitType>(showMultiUnit && initialOrderUnits.length > 0 ? 'multi_unit' : 'single_unit');
    const [address, setAddress] = useState(propertyDetail.address || '')
    const [street2, setStreet2] = useState(propertyDetail.street2 || '')
    const [orderUnits, setOrderUnits] = useState(initialOrderUnits.length > 0 ? initialOrderUnits : [{ name: '', id: null}, { name: '', id: null}]);
    const [deletedOrderUnitIds, setDeletedOrderUnitIds] = useState<string[]>([]);

    const { ref: mapRef } = usePlacesWidget({
        apiKey: apiKey,
        onPlaceSelected: (place: any) => {
            setAddress(place.formatted_address);
        },
        options: {
            types: ['geocode'],
            componentRestrictions: {country: ['us', 'ca']}
        },
    });

    const unitTypeOnChange = (newUnitType: UnitType) => {
        setUnitType(newUnitType);
        if (newUnitType == 'multi_unit') {
            updateOrderUnitName(street2, 0);
        } else {
            setStreet2(orderUnits[0].name)
        }
    }

    const updateOrderUnitName = (name, index) => {
        let newOrderUnits = [...orderUnits];
        newOrderUnits[index] = {...orderUnits[index], name: name};
        setOrderUnits(newOrderUnits);
    }

    const removeOrderUnit = (index) => {
        if (orderUnits[index].id) {
            setDeletedOrderUnitIds([...deletedOrderUnitIds, orderUnits[index].id]);
        }
        let newOrderUnits = [...orderUnits];
        newOrderUnits.splice(index, 1);
        setOrderUnits(newOrderUnits);
    }

    const addOrderUnit = () => {
        setOrderUnits([...orderUnits, {id: null, name: ''}])
    }

    return (
        <>
            {(showMultiUnit || showLot) && (
                <div className="form-group">
                    {showMultiUnit && (
                        <label>
                            How many units need inspections?
                        </label>
                    )}
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <input className="box_checkbox_radio_input"
                                   name="order[property_attributes][unit_type]"
                                   type="radio"
                                   value="single_unit"
                                   checked={unitType === 'single_unit'}
                                   onChange={(e) => {
                                       if (e.target.checked) {
                                           unitTypeOnChange('single_unit')
                                       }
                                   }}/>
                            <label className="box_checkbox_radio_label" onClick={() => {
                                unitTypeOnChange('single_unit')
                            }}>
                                <div className="box_checkbox_radio_label_content">
                                    <span className="unit_type_icon"><SingleFamilySVG/></span>
                                    Single unit
                                </div>
                            </label>
                        </div>
                        {showMultiUnit && (
                            <div className="col-12 col-lg-6">
                                <input className="box_checkbox_radio_input unit_type_input"
                                       name="order[property_attributes][unit_type]"
                                       type="radio"
                                       value="multi_unit"
                                       checked={unitType === 'multi_unit'}
                                       onChange={(e) => {
                                           if (e.target.checked) {
                                               unitTypeOnChange('multi_unit')
                                           }
                                       }}/>
                                <label className="box_checkbox_radio_label" onClick={() => {
                                    unitTypeOnChange('multi_unit')
                                }}>
                                    <div className="box_checkbox_radio_label_content">
                                        <span className="unit_type_icon"><PropManagementSVG/></span>
                                        Multiple units
                                    </div>
                                </label>
                            </div>
                        )}
                        {showLot && (
                          <div className="col-12 col-lg-6">
                            <input className="box_checkbox_radio_input"
                                   name="order[property_attributes][unit_type]"
                                   type="radio"
                                   value="lot"
                                   checked={unitType === 'lot'}
                                   onChange={(e) => {
                                       if (e.target.checked) {
                                           unitTypeOnChange('lot')
                                       }
                                   }}/>
                            <label className="box_checkbox_radio_label" onClick={() => {
                                unitTypeOnChange('lot')
                            }}>
                                <div className="box_checkbox_radio_label_content">
                                    <span className="unit_type_icon"><EmptyLandSVG/></span>
                                    Lot
                                </div>
                            </label>
                          </div>
                      )}
                    </div>
                </div>
            )}

            {unitType !== 'lot' && (
                <div className="form-group">
                    <label htmlFor="property_address_input">
                        Address
                    </label>
                    <input className="form-control"
                           placeholder=""
                           id="property_address_input"
                           ref={mapRef}
                           required={true}
                           type="text"
                           value={address}
                           name="order[property_attributes][address]"
                           onChange={(e) => setAddress(e.target.value)}/>
                </div>
            )}

            {unitType === 'single_unit' && (
                <div className="form-group street_2_form_group">
                    <label htmlFor="order_property_attributes_street_2">
                        Unit / Apt / Suite (optional)
                    </label>
                    <input className="form-control"
                           type="text"
                           id="order_property_attributes_street_2"
                           name="order[property_attributes][street_2]"
                           value={street2}
                           onChange={(e) => setStreet2(e.target.value)}/>
                </div>
            )}

            {unitType === 'multi_unit' && (
                <>
                    <input type="hidden" value={'multi_family'} name="order[property_attributes][home_type]"/>

                    {orderUnits.map((orderUnit, index) => (
                        <div key={index.toString()}>
                            <div className="unit_apt_suite_row">
                                <div className="form-group order_unit_form_group">
                                    <label htmlFor={`order_order_units_attributes_${index}_name`}>Unit / Apt /
                                        Suite</label>
                                    <input className="form-control"
                                           autoComplete="off"
                                           required={true}
                                           type="text"
                                           value={orderUnit.name}
                                           onChange={(e) => {
                                               updateOrderUnitName(e.target.value, index)
                                           }}
                                           name={`order[order_units_attributes][${index}][name]`}
                                           id={`order_order_units_attributes_${index}_name`}/>
                                </div>
                                <button className="link_as_button" type="button" hidden={index < 2}  onClick={() => removeOrderUnit(index)}>
                                    <span className="remove_unit_minus_icon"><MinusOutlined/></span>
                                </button>
                            </div>
                            {orderUnit.id && (
                                <input type="hidden"
                                       value={orderUnit.id}
                                       name={`order[order_units_attributes][${index}][id]`}/>
                            )}
                        </div>
                    ))}

                    <div className="float-left mb-5">
                        <button type="button" className="link_as_button font-weight-bold" onClick={addOrderUnit}>
                            <span className="add_unit_plus_icon"><PlusOutlined/></span>
                            Add unit
                        </button>
                    </div>
                </>
            )
            }

            {unitType === 'lot' && (
              <>
                  <div className="form-group">
                      <label htmlFor="order_property_attributes_address">Address (Optional)</label>
                      <input className="form-control" type="text" id="order_property_attributes_address"
                             name="order[property_attributes][address]"/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="order_community_name">Project name (Optional)</label>
                      <input className="form-control" type="text" id="order_community_name"
                             name="order[community_name]"/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="order_property_attributes_latitude">Latitude</label>
                      <input className="form-control" type="number" id="order_property_attributes_latitude" min="-90"
                             max="90" step="0.00000000000001"
                             required={true} name="order[property_attributes][latitude]"/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="order_property_attributes_longitude">Longitude (Should be negative for the
                          USA)</label>
                      <input className="form-control" type="number" id="order_property_attributes_longitude" min="-180"
                             max="180" step="0.00000000000001"
                             required={true} name="order[property_attributes][longitude]"/>
                  </div>
              </>
            )}

            { /* Create destroy form entry for all Ids being deleted, and also for all orderUnits if there
                  was orderUnits before and now the user switched back to Single Unit */}
            {[
                ...deletedOrderUnitIds,
                ...(unitType === 'single_unit' ? orderUnits.map((orderUnit) => orderUnit.id).filter(n => n) : [])
            ].map((deletedOrderUnitId, index) => (
                <div key={index.toString()}>
                    <input type="hidden"
                           value={deletedOrderUnitId}
                           name={`order[order_units_attributes][${index + (unitType === 'multi_unit' ? orderUnits.length : 0)}][id]`}/>
                    <input type="hidden"
                           value={1}
                           name={`order[order_units_attributes][${index + (unitType === 'multi_unit' ? orderUnits.length : 0)}][_destroy]`}/>
                </div>
            ))}
        </>
    )
};

export default AddressUnits;
